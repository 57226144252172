<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1640, y: gridHeight }"
      >
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <!-- todo 需要补齐  -->
        <template #pushStatus="{ record }">
          <div v-if="record.pushStatus === 0">未发布</div>
          <div v-else-if="record.pushStatus === 1">已发布</div>
        </template>
        <template #isVideo="{ record }">
          <div v-if="record.isVideo === 0">否</div>
          <div v-else-if="record.isVideo === 1">是</div>
        </template>
        <template #partnerType="{ record }">
          <div v-if="record.partnerType === 0">钻石</div>
          <div v-else-if="record.partnerType === 1">黄金</div>
          <div v-else>白银</div>
        </template>
        <!--      首页显示状态  ("首页展示状态 1:已展示  0:为展示")   -->
        <template #isHome="{ record }">
          <a-switch
            :checked="record.isHome"
            checked-children="是"
            un-checked-children="否"
            :checked-value="Number(1)"
            :un-checked-value="Number(0)"
            @click="handleClickSwitch(record)"
          />
        </template>
        <template #partnerLogo="{ record }">
          <img :src="record.partnerLogo" width="75" />
        </template>
        <template #partnerHomeLogo="{ record }">
          <img :src="record.partnerHomeLogo" width="75" />
        </template>

        <!--      首页显示状态  ("首页展示状态 1:已展示  0:为展示")   -->
        <template #showStatus="{ record }">
          <a-switch
            :checked="record.showStatus"
            checked-children="启用"
            un-checked-children="禁用"
            @click="handleClickSwitch(record)"
          />
        </template>
      </grid>
    </div>
  </div>

  <iss-edit
    v-model:visible="visible"
    :init-value="activeItem"
    @fnOk="handleFnOkByEdit"
  />
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { Tag, Switch, message } from 'ant-design-vue';
// import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import partnerApi from '@/api/partner';
import issEdit from './components/edit';
// import { EyeOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    ATag: Tag,
    issEdit,
    // EyeOutlined,
    ASwitch: Switch,
  },
  setup() {
    const gridRef = ref();
    const store = useStore();
    const state = reactive({
      visible: false,
      search: {},
      // TagList: [],
      activeItem: {},
    });
    // const router = useRouter();
    // contactApi.getTag('', {}).then(list => {
    //   list?.forEach(item => {
    //     state.TagList.push({ value: item.tagCode, label: item.tagName });
    //   });
    // });

    return {
      gridRef,
      store,
      ...toRefs(state),
      items: [
        { key: 'partnerName', label: '伙伴名称' },
        {
          key: 'pushStatus',
          label: '发布状态',
          type: 'select',
          dataset: [
            { value: '1', label: '已发布' },
            { value: '0', label: '未发布' },
          ],
        },

        {
          key: 'partnerType',
          label: '伙伴类型',
          type: 'select',
          dataset: [
            { value: 0, label: '钻石' },
            { value: 1, label: '黄金' },
            { value: 2, label: '白银' },
          ],
        },
      ],
      columns: [
        {
          dataIndex: 'partnerName',
          title: '伙伴名称',
          width: 80,
          ellipsis: true,
        },
        {
          dataIndex: 'partnerType',
          title: '伙伴类型',
          width: 180,
          ellipsis: true,
          slots: { customRender: 'partnerType' },
        },
        {
          dataIndex: 'partnerHomeLogo',
          title: '伙伴logo（首页）',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'partnerHomeLogo' },
        },
        {
          dataIndex: 'partnerLogo',
          title: '伙伴logo（二级）',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'partnerLogo' },
        },
        {
          dataIndex: 'isHome',
          title: '首页展示状态',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'isHome' },
        },
        {
          dataIndex: 'partnerUrl',
          title: '伙伴链接',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'isVideo',
          title: '伙伴是否有视频',
          width: 120,
          slots: { customRender: 'isVideo' },
          ellipsis: true,
        },
        // 点击打开议程 详情页
        {
          dataIndex: 'pushStatus',
          title: '发布状态',
          width: 120,
          slots: { customRender: 'pushStatus' },
          ellipsis: true,
        },

        {
          dataIndex: 'sort',
          title: '排序',
          width: 120,
          slots: { customRender: 'sort' },
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 80,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'classify:add',
          fnClick: () => {
            state.visible = true;
            state.activeItem = {};
          },
        },

        // todo 发布接口
        {
          type: 'isPublish',
          label: '发布',
          icon: 'ExportOutlined',
          permission: 'agenda:publish',
          fnClick: () => {
            partnerApi.partnerPublish('', '').then(() => {
              message.success('发布成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '编辑',
          permission: 'agenda:update',
          fnClick: record => {
            state.visible = true;
            state.activeItem = { ...record };
            let id = record.id;
            partnerApi.getfindById('', id).then(res => {
              state.activeItem = { ...res };
            });
          },
        },
        {
          type: 'delete',
          permission: 'resources:delete',
          fnClick: ({ id }) => {
            console.log('id', id);
            partnerApi.delete('resources:delete', { id: id }).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      url: partnerApi.pageUrl,
      gridHeight: document.body.clientHeight - 386,
      // { id, showStatus }
      handleClickSwitch(record) {
        // showStatus = !showStatus;
        // todo 需要联调首页显示状态
        record.isHome = record.isHome === 0 ? 1 : 0;
        partnerApi.update('', record).then(res => {
          if (res) {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          }
        });
      },

      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        state.search = temp;
      },
      handleFnOkByEdit: value => {
        let type = 'add';
        const { id } = value;
        id && (type = 'update');

        partnerApi[type](`stand:${type}`, value).then(() => {
          message.success('操作成功');
          state.visible = false;
          gridRef.value.refreshGrid();
          setTimeout(() => {}, 1500);
        });
        // 保存失败后 恢复保存按钮
        setTimeout(() => {}, 1500);
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
