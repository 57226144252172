'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/partner/page',
  getList(code, params) {
    return http.get('/authority/org/tree', { params, code });
  },
  add(code, data) {
    return http.post('/partner', data, { code });
  },
  update(code, data) {
    return http.put('/partner', data, { code });
  },
  delete(code, params) {
    return http.delete('/partner/' + params.id);
  },
  // 查询
  getfindById(code, id) {
    return http.get(`/partner/${id}`, { code });
  },
  partnerPublish(code, data) {
    return http.post('/partner/publish', data, {
      code,
    });
  },
};
